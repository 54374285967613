<template>
  <div class="animated fadeIn">
    <b-card>
      <div class="card-body">
        <form>
          <h5>
            Subscriber list details
            <b-spinner v-if="isLoading" small type="grow" class="mb-1" />
          </h5>
          <hr />

          <b-row>
            <b-col lg="3" md="2" sm="2">
              <inline-input
                :id="controls.list_name.id"
                :value="data.list_name"
                :label="controls.list_name.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
            <b-col lg="3" md="2" sm="2">
              <inline-select
                :id="controls.category.id"
                :value="data.category"
                :label="controls.category.label"
                :readonly="controls.category.readonly"
                :options="controls.category.options"
                :allow-empty="false"
                :mode="mode"
                @changed="updateSelect"
                :required="true"
              />
            </b-col>

            <!-- Hide if Volunteer List OR KOVAL lists -->
            <b-col lg="3" md="2" sm="2" v-if="id !== '3' && id !== '5'">
              <inline-select
                :id="controls.custom_filter.id"
                :value="data.custom_filter"
                :label="controls.custom_filter.label"
                :readonly="controls.custom_filter.readonly"
                :options="controls.custom_filter.options"
                :allow-empty="true"
                :mode="mode"
                @changed="updateSelect"
                :required="false"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col lg="12" md="12" sm="12">
              <inline-input
                :id="controls.description.id"
                :value="data.description"
                :label="controls.description.label"
                :readonly="false"
                :mode="mode"
                :required="false"
                @changed="updateField"
              />
            </b-col>
          </b-row>

          <hr />
        </form>

        <table-custom
          ref="dataTable"
          :name="`custom_table_phplist_contacts`"
          :loading="dataTable.isLoading"
          :data="dataTable.dataSet"
          :options="dataTable.options"
        />

        <hr />
        <form-submission-actions
          :mode="mode"
          :loading="{
            save: saveInProgress,
            saveAndView: saveAndViewInProgress
          }"
          :buttons-visibility="{
            previous: $customTable.getPrevKey($route.meta.module, id),
            next: $customTable.getNextKey($route.meta.module, id)
          }"
          @previous-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getPrevKey($route.meta.module, id)
              }
            })
          "
          @next-item="
            $router.push({
              name: $route.name,
              params: {
                action: $route.params.action,
                id: $customTable.getNextKey($route.meta.module, id)
              }
            })
          "
          @save="
            save('tabular').then(response =>
              response ? $router.push({ name: 'Subscribers lists' }) : false
            )
          "
          @save-and-view="
            save('view').then(response =>
              response
                ? $router.push({
                    name: 'Subscribers list submission',
                    params: { action: 'view', id: response }
                  })
                : false
            )
          "
          @edit="
            $router.push({
              name: 'Subscribers list submission',
              params: { action: 'edit', id: id }
            })
          "
          @back="$router.push($store.getters['router/previousRoute'])"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import FormSubmissionActions from '@/components/FormSubmissionActions'

import InlineInput from '@/components/InlineInput'
import InlineSelect from '@/components/InlineSelectMultiple'

import TableCustom from '@/components/TableCustom'

import { mapState, mapActions } from 'vuex'

export default {
  name: 'PhpListSubmissionForm',
  props: {
    id: { type: [Number, String], default: '' },
    action: { type: String, default: '' }
  },
  components: {
    InlineInput,
    InlineSelect,

    FormSubmissionActions,
    TableCustom
  },
  data: function () {
    return {
      mode: undefined,
      isLoading: false,
      saveInProgress: false,
      saveAndViewInProgress: false,
      customFilterOptions: { accounts: [], employees: [] },
      controls: {
        buttons: {
          edit: {
            id: 'button:details_edit'
          },
          save: {
            id: 'button:details_save'
          }
        },
        list_name: {
          id: 'input:list_name',
          label: 'List Name',
          changed: false,
          readonly: false
        },
        category: {
          id: 'select:category',
          label: 'Category',
          options: [],
          changed: false,
          readonly: false
        },

        custom_filter: {
          id: 'select:custom_filter',
          label: 'Custom filter',
          options: [],
          changed: false,
          readonly: false
        },

        description: {
          id: 'input:description',
          label: 'Description',
          changed: false,
          readonly: false
        }
      },
      data: {
        id: '',
        list_name: '',
        category: {
          id: '',
          label: ''
        },
        custom_filter: {
          id: '',
          label: ''
        },

        description: ''
      },
      dataTable: {
        //loading: false,
        isLoading: false,
        dataSet: [],
        options: {
          columns: [
            'Account Name',
            'Category',
            'Last Name',
            'First Name',
            'Email'
          ],
          filterable: [
            'Account Name',
            'Category',
            'Last Name',
            'First Name',
            'Email'
          ],
          footerHeadings: false,
          perPage: 50,
          showActions: false
        }
      }
    }
  },
  computed: mapState({
    profile: state => state.profile
  }),
  created () {
    this.initialize()
  },
  mounted: function () {},
  methods: {
    ...mapActions('profile', ['fetchProfile']),
    initialize: async function () {
      if (this.action === 'create') {
        this.mode = this.$constants.FORM_MODE.CREATE
      }
      if (this.action === 'edit') {
        this.mode = this.$constants.FORM_MODE.EDIT
      }
      if (this.action === 'view') {
        this.mode = this.$constants.FORM_MODE.VIEW
      }

      this.data.id = this.id

      this.fetchDropdowns()

      if (this.mode !== this.$constants.FORM_MODE.CREATE && this.id) {
        await this.fetchData(this.id)

        await this.fetchContacts(this.id)
      }
    },
    fetchDropdowns () {
      this.$api.get('phplist/categories').then(response => {
        this.controls.category.options = response.map(item => {
          return {
            id: item.id,
            label: item.name
          }
        })
      })

      this.$api.get('phplist/custom-filters').then(response => {
        this.customFilterOptions.accounts = response.map(item => {
          return {
            id: item.id,
            label: `${item.name} (${item.abbr})`
          }
        })
      })
      this.$api.get('phplist/custom-filters-employee').then(response => {
        this.customFilterOptions.employees = response.map(item => {
          return {
            id: item.id,
            label: `${item.name} (${item.abbr})`
          }
        })
      })
    },
    save (_mode) {
      if (!this.$form.testForm(this)) {
        this.$form.makeToastError('Form contains errors')
        return Promise.resolve(false)
      }

      this.saveInProgress = _mode === 'tabular'
      this.saveAndViewInProgress = _mode === 'view'

      let method = ''
      let url = ''

      if (this.mode === this.$constants.FORM_MODE.CREATE) {
        method = 'put'
        url = 'phplist'
      }
      if (this.mode === this.$constants.FORM_MODE.EDIT) {
        method = 'put'
        url = `phplist/${this.data.id}`
      }

      this.isLoading = true

      return this.$api[method](url, this.data)
        .then(response => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastInfo(response.message)

          this.$router.currentRoute.params.id = response.id

          return response.id
        })
        .catch(error => {
          this.isLoading = false
          this.saveInProgress = false
          this.saveAndViewInProgress = false

          this.$form.makeToastError(error.message)

          return Promise.resolve(false)
        })
    },
    updateField (field, value, mode) {
      this.$form.updateField('phplist', this, field, value, mode)
    },
    updateSelect (id, value) {
      this.$form.updateField('phplist', this, id, value, this.mode)
    },

    fetchData: async function (id) {
      let self = this

      this.isLoading = true

      return this.$api
        .get(`phplist/${id}`)
        .then(response => {
          this.isLoading = false

          if (self.$_.isEmpty(response)) return

          self.data.id = self.id

          self.data.list_name = response['List Name']

          self.data.category = {
            id: response['Category'],
            label: response['Category']
          }

          if (response['Category'] === 'Koval Internal') {
            self.controls.custom_filter.options =
              self.customFilterOptions.employees
          } else {
            self.controls.custom_filter.options =
              self.customFilterOptions.accounts
          }

          self.data.custom_filter = {
            id: response['Filter ID'],
            label: response['Filter Name']
          }

          self.data.description = response['Description']
        })
        .catch(error => {
          this.isLoading = false

          console.log(error)
        })
    },
    fetchContacts (id) {
      let self = this

      self.dataTable.isLoading = true
      let url = `phplist/${id}/contacts`
      if (self.data.category.label === 'Koval Internal') {
        url = `phplist/${id}/contacts-employee`
      }

      return this.$api
        .get(url)
        .then(response => {
          self.dataTable.isLoading = false

          self.dataTable.dataSet = response
        })
        .catch(error => {
          self.dataTable.isLoading = false

          console.log(error)
        })
    }
  },
  watch: {
    'data.category': function (newVal) {
      if (newVal.label === 'Koval Internal') {
        this.controls.custom_filter.options = this.customFilterOptions.employees
      } else {
        this.controls.custom_filter.options = this.customFilterOptions.accounts
      }
    }
  }
}
</script>

<style></style>
